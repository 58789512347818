<!--
 * @Description: 审核列表
 * @Author: ChenXueLin
 * @Date: 2021-10-09 10:10:52
 * @LastEditTime: 2022-07-15 15:42:15
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <div class="tab-box fixed-section">
        <div
          class="tab "
          :class="{
            activeTab: currentIndex == index ? true : false
          }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="handleClick(index, item.id)"
        >
          {{ item.label }}
        </div>
      </div>
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="businessCode">
              <e6-vr-select
                v-model="searchForm.businessCode"
                :data="businessTypeList"
                placeholder="单据类型"
                title="单据类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              class="search-item--1"
              prop="flowStatus"
              v-if="currentIndex == 2"
            >
              <e6-vr-select
                v-model="searchForm.flowStatus"
                :data="flowStatusList"
                placeholder="流程状态"
                title="流程状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="time">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="发起时间"
                ref="effectiveDatePicker"
                v-model="searchForm.time"
                title="发起时间"
                :picker-options="pickerOptions('searchForm.time')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="发起时间（始）"
                end-placeholder="发起时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- <section class="search-wrapper" ref="searchWrapper"> -->
      <!-- 搜索头部 end -->
      <!--        
      </section> -->
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getApprovalList, findDownList } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "auditList",
  data() {
    return {
      currentIndex: 0,
      tabList: [
        {
          id: "created",
          label: "已发起"
        },
        {
          id: "todo",
          label: "待处理"
        },
        {
          id: "handled",
          label: "已处理"
        }
      ],
      searchForm: {
        queryType: "created", //审核状态（已发起：created；待处理：todo；已处理：handled）
        businessCode: "", //单据类型
        flowStatus: "", //流程状态
        time: [],
        beginDate: "", //发起开始日日期
        endDate: "", //发起结束日期
        pageIndex: 1,
        pageSize: 20
      },
      businessTypeList: [], //单据类型
      flowStatusList: [], //流程状态下拉框
      typeList: [],
      total: 0,
      columnData: [
        {
          fieldName: "approvalCode",
          display: true,
          fieldLabel: "审批单号",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "approvalBusinessName",
          display: true,
          fieldLabel: "单据类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "flowStatus",
          display: true,
          fieldLabel: "流程状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "applyUserName",
          display: true,
          fieldLabel: "申请人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "applyDate",
          display: true,
          fieldLabel: "发起时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishDate",
          display: true,
          fieldLabel: "完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      queryListAPI: getApprovalList
    };
  },
  components: {},
  mixins: [base, listPage, listPageReszie],
  watch: {
    // 发起时间
    "searchForm.time": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.time = this.searchForm.time.reverse();
        }
        this.searchForm.beginDate = val[0] || "";
        this.searchForm.endDate = val[1] || "";
      }
    }
  },
  computed: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList(["approvalBusinessCode", "approvalFlowStatus"])
        ];
        let [res] = await Promise.all(promiseList);
        //单据类型
        this.businessTypeList = this.getFreezeResponse(res, {
          path: "data.approvalBusinessCode"
        });
        //流程状态
        this.flowStatusList = this.getFreezeResponse(res, {
          path: "data.approvalFlowStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      let route = {
        name: "auditList/approveDetails",
        params: {
          refresh: true,
          label: row.label,
          approveId: row.approvalId,
          approvalCode: row.approvalCode, // 审批单号
          approvalBusinessId: row.approvalBusinessId, // 单据ID: 对应详情id 如：客户详情-->客户ID
          approvalBusinessCode: row.approvalBusinessCode, // 单据类型code
          origin: this.$route.name
        }
      };
      this.routerPush(route);
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    handleClick(idx, id) {
      if (idx == this.currentIndex) {
        return;
      }
      this.currentIndex = idx;
      this.searchForm.queryType = id;
      this.searchForm.pageIndex = 1;
      this.queryList();
    }
  }
};
</script>
<style lang="scss" scoped>
.tab-box {
  width: 100%;
  display: flex;
  height: 42px;
  align-items: center;
  border-bottom: 1px solid #edf0f5;
  .tab {
    font-size: 16px;
    color: #666666;
    margin-left: 26px;
    position: relative;
  }
  .activeTab {
    font-weight: 500;
    color: #46bfea;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background: #46bfea;
      position: absolute;
      right: 0px;
      bottom: -12px;
    }
  }
}
/deep/.el-tabs {
  height: 100%;
  overflow-y: auto;
}
</style>
